<template>
  <div class="px-5">
    <Form
      title="Editar torneo"
      ref="tournamentForm"
      :deletable="true"
      :loading.sync="loading"
      @save="save"
      @toggleVisibility="toggleVisibility"
    />
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
import Form from "./Form.vue"
export default {
  components: {
    Form
  },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    toggleVisibility() {
      this.$store
        .dispatch("global/changeStatus", {
          payload: {
            id: this.$refs.tournamentForm.form.id,
            status: !this.$refs.tournamentForm.visible ? 1 : 0
          },
          route: "/tournament",
          module: "tournament"
        })
        .then((response) => {
          notify().then(({ notification }) => {
            notification("master-status", response.code, this.$snotify)
          })
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    save() {
      let form = this.$refs.tournamentForm.form
      this.loading = true

      this.$store
        .dispatch("global/update", {
          payload: {
            id: form.id,
            id_tournament_region: form.id_tournament_region,
            name: form.name,
            date_from: form.date_from,
            date_to: form.date_to,
            url_logo: form.url_logo,
            url_header: form.url_header
          },
          route: "/tournament/update",
          module: "tournament"
        })
        .then((response) => {
          notify().then(({ notification }) => {
            notification("master-update", response.code, this.$snotify)
          })
          this.loading = false

          if ([200, 201].includes(response.code)) {
            this.$router.push("/torneos")
          }
        })
        .catch((error) => {
          this.loading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    deletePlayer() {
      this.$dialog
        .confirm({
          title: "¿Estás seguro?",
          text: `Esta acción eliminará permanentemente al torneo.`,
          icon: "question",
          escToClose: true,
          cancelButtonText: "Cancelar",
          okButtonText: "Ok"
        })
        .then((result) => {
          if (result.isOk) {
            this.$store
              .dispatch("global/changeStatus", {
                payload: {
                  id: this.$refs.tournamentForm.form.id,
                  status: 2
                },
                route: "/tournament",
                module: "tournament"
              })
              .then((response) => {
                notify().then(({ notification }) => {
                  notification(
                    "master-status-delete",
                    response.code,
                    this.$snotify
                  )
                })
                if (response.code === 200) this.$router.push("/torneos")
              })
              .catch((error) => {
                notify().then(({ notification }) => {
                  notification("error", error.code, this.$snotify)
                })
              })
          }
        })
    },
    getItem() {
      this.$store
        .dispatch("global/getItem", {
          route: "/tournament",
          module: "tournament",
          id: this.$route.params.id
        })
        .then((response) => {
          this.form = response.data
          this.$refs.tournamentForm.form = this.form
          this.$refs.tournamentForm.visible =
            response.data && response.data.active === 1 ? true : false
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    }
  },
  created() {
    this.getItem()
  }
}
</script>
